import React from 'react';
import {TaInputText} from "components/taUi/taInputText";
import {TaToolbar} from "components/taUi/taToolbar/taToolbar";
import {TaButton} from "components/taUi/taButton/taButton";
import * as PropTypes from "prop-types";
import {TaInputGroup} from "components/taUi/taInputGroup/taInputGroup";
import {TaPane} from "components/taUi/taPane/taPane";
import {TaToolbarGroup} from "components/taUi/taToolbar/taToolbarGroup";
import {
  TaModal,
  TaModalContent,
  TaModalContentMargin,
  TaModalFooter,
  TaModalHeader
} from "components/taUi/taModal/taModal";
import {LANGUAGE_OPTIONS} from "../../../misc/config";
import {reorderArray} from "../../../misc/utils";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {TaInputCheckbox} from "../../taUi/taInputCheckbox/taInputCheckbox";
import {GlossaryEditor} from "./GlossaryEditor";
import GlossaryEditorFooter from "./GlossaryEditorFooter";

export class GlossaryDetail extends React.PureComponent {

  static propTypes = {
    list: PropTypes.object.isRequired,
    items: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    onSave: PropTypes.func.isRequired
  };

  constructor() {
    super();

    this.tableBodyRef = React.createRef();

    this.defaultMutations = () => {
      return {
        index: {},
        added: {},
        edited: {},
        removed: {},
        committed: {},
        rejected: {},
        data: {},
        error: {}
      };
    };

    this.state = {
      items: [],
      mutations: Object.assign({}, this.defaultMutations()),
      selected: {
        items: {},
        count: 0,
        isNone: true,
        isAll: false,
        isSome: false
      },
      modalLanguages: {
        open: false,
        columns: []
      },
      modalWarning: {
        open: false
      }
    };

  }

  componentDidMount() {
    this.setState({
      items: this.filterListItems()
    });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.isSaving &&
      !!(this.props.isSaving) !== !!(prevProps.isSaving)
    ) {
      const mutations = this.defaultMutations()
      this.setState({
        mutations: mutations,
        items: this.filterListItems(mutations),
        updated: Date.now()
      });

    } else if (
      this.props.updated &&
      this.props.updated !== prevProps.updated
    ) {
      this.setState({
        items: this.filterListItems(),
        updated: Date.now()
      });
    }
    if (this.props.scrollToBottom !== prevProps.scrollToBottom) {
      this.tableBodyRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end'
      });
    }
  }

  selected_toggleAll = () => {

    let newItems = {};

    if (
      !this.state.selected.isAll
    ) {

      if (
        this.state.items
      ) {
        this.state.items.forEach(row => {
          if (!this.state.mutations.removed[row.id]) {
            newItems[row.id] = true;
          }
        });
      }
      Object.keys(this.state.mutations.added).forEach(key => {
        newItems[key] = true;
      });
    }

    this.selected_setState(newItems);

  };
  selected_unselectAll = () => {
    this.selected_setState({});
  };
  selected_toggleItem = (itemId) => {
    let newItems = Object.assign({}, this.state.selected.items);
    if (newItems[itemId]) {
      delete newItems[itemId];
    } else {
      newItems[itemId] = true;
    }
    this.selected_setState(newItems);
  };
  selected_unselectItem = (itemId) => {
    let newItems = Object.assign({}, this.state.selected.items);
    if (newItems[itemId]) {
      delete newItems[itemId];
    }
    this.selected_setState(newItems);
  };
  selected_calcState = (newItems) => {

    const props = this.props;
    const state = this.state;

    const loadedItems = (props.items) ? props.items : [];
    const loadedItemCount = loadedItems.length;

    const addedItemsCount = Object.keys(state.mutations.added).length;
    const removedItemsCount = Object.keys(state.mutations.removed).length;

    const totalItemsCount = loadedItemCount - removedItemsCount + addedItemsCount;

    const selectedItemsCount = Object.keys(newItems).length;

    const isNoneSelected = (!selectedItemsCount);
    const isAllSelected = (!isNoneSelected && totalItemsCount === selectedItemsCount);
    const isSomeSelected = (!isAllSelected && totalItemsCount > 1);

    return {
      items: newItems,
      count: selectedItemsCount,
      isNone: isNoneSelected,
      isAll: isAllSelected,
      isSome: isSomeSelected
    };

  };
  selected_setState = (newItems) => {
    this.setState({
      selected: this.selected_calcState(newItems)
    });
  };

  openRemoveWarningModal = () => {

    const state = this.state;
    const selected = state.selected;
    const languages = this.getVisibleLanguages();

    if (selected.count) {

      const items = state.items.filter(item => selected.items[item.id]);

      this.setState({
        modalWarning: {
          open: true,
          title: (items.length > 1) ? 'Remove ' + items.length + ' Terms' : 'Remove Term',
          body: <React.Fragment>
            <p>Would you like to remove the following term{(items.length > 1) ? 's' : ''} from this list?</p>
            <ul>
              {
                items.map(item => {
                  let strings = [];
                  languages.forEach(lng => {
                    strings.push(item[lng.key]);
                  });
                  return <li key={item.id}>{strings.join(' / ')}</li>;
                })
              }
            </ul>
          </React.Fragment>
        }
      });
    }

  };

  closeRemoveWarningModal = () => {
    this.setState({
      modalWarning: {
        open: false
      }
    });
  };

  onRemoveWarningModalConfirm = () => {

    this.action_bulk('remove');
    this.closeRemoveWarningModal();

  };

  action_bulk = (action) => {

    const selected = this.state.selected;
    if (selected.count) {

      let newState = {
        selected: this.selected_calcState({})
      };

      let newSelected = {};
      let newMutations = Object.assign({}, this.state.mutations);

      Object.keys(selected.items).forEach(key => {

        const timestamp = Date.now();
        if (
          action === 'commit' ||
          action === 'reject'
        ) {

          let newItem = (newMutations.data[key]) ? Object.assign({}, newMutations.data[key]) : {};
          newItem.is_pending = (action !== 'commit');

          if (action === 'commit') {
            newMutations.committed[key] = timestamp;
            delete newMutations.rejected[key];
          } else {
            newMutations.rejected[key] = timestamp;
            delete newMutations.committed[key];
          }

          newMutations.data[key] = newItem;
          // newSelected[key] = true;


        } else if (
          action === 'remove'
        ) {

          if (newMutations.added[key]) {
            delete newMutations.added[key];
          } else {
            newMutations.removed[key] = true;
          }
          delete newMutations.edited[key];
          delete newMutations.data[key];
          delete newMutations.error[key];
        }

        newMutations.index[key] = timestamp;

      });

      if (
        action === 'remove'
      ) {
        newState.selected = newSelected;
      }
      newState.mutations = newMutations;
      newState.items = this.filterListItems(newMutations);

      this.setState((state, props) => {
        return newState;
      });

    }

  };

  filterListItems = (newSearch, newFilter, resetRows, newMutations) => {

    const props = this.props;
    const state = this.state;

    const mutations = (newMutations) ? newMutations : state.mutations;
    const rows = (props.isLastPage) ? props.items.concat(
      Object.keys(mutations.added).map(id => mutations.data[id])
    ) : props.items;

    return rows.filter(row => {
      if (
        typeof mutations.removed !== 'undefined' &&
        typeof mutations.removed[row.id] !== 'undefined'
      ) {
        return false;
      } else {
        return true;
      }
    });

  };

  action_save = (force_merge) => {

    const mutations = this.state.mutations;
    const languages = this.getLanguages();
    const save = this.props.onSave;
    const errorKeys = Object.keys(mutations.error);

    if (typeof save === 'function') {

      if (errorKeys.length) {
        let errors = [];
        errorKeys.forEach(id => {
          const errorData = mutations.error[id];
          Object.keys(errorData.fields).forEach(lng => {
            errors.push(<span>Term #{errorData.index + 1}: <b style={{textTransform: 'capitalize'}}>{lng}</b> is required. </span>);
          });
        });
        const errorCount = errors.length;

        this.setState({
          modalWarning: {
            open: true,
            title: 'Invalid Values',
            isError: true,
            body: (
              <React.Fragment>
                <p>The changes cannot be saved. Please review the
                  following {(errorCount > 1) ? errorCount + ' issues' : 'issue'}:</p>
                <ul>
                  {
                    errors.map((error, errorIndex) => {
                      return <li key={errorIndex}>{error}</li>;
                    })
                  }
                </ul>
              </React.Fragment>
            )
          }
        });
      } else {
        save(mutations, languages, force_merge);
      }

    }

  };

  onCellEdit = (property, currentValue, newValue, row, rowIndex) => {
    if (currentValue !== newValue) {

      const id = row.id;
      const time = Date.now();

      let newMutations = Object.assign({}, this.state.mutations);
      newMutations.data[id] = row;
      newMutations.data[id][property] = newValue;
      newMutations.edited[id] = time;
      newMutations.index[id] = time;

      const isInValid = false; // this.cellIsInValid(newValue);
      if (isInValid) {
        if (typeof newMutations.error[id] === 'undefined') {
          newMutations.error[id] = {
            id: id,
            index: rowIndex,
            fields: {}
          };
        }
        newMutations.error[id].fields[property] = isInValid;

      } else if (newMutations.error[id]) {
        if (typeof newMutations.error[id].fields[property] !== 'undefined') {
          delete newMutations.error[id].fields[property];
        }
        if (!Object.keys(newMutations.error[id].fields).length) {
          delete newMutations.error[id];
        }
      }

      this.setState({
        mutations: newMutations,
        reRenderKey: time
      });

    }
  };

  cellIsInValid = (value) => {
    return (!value.length) ? 'required' : false;
  };

  getLanguages = () => {
    return [...LANGUAGE_OPTIONS];
  };
  getVisibleLanguages = () => {
    const settings = this.props.columns;
    return this.getLanguages().filter(lang => {
      return settings[lang.key].visible;
    }).sort((a, b) => (
        settings[a.key].order - settings[b.key].order
    ));
  };

  onDismissMergeModal = () => {

    let newState = {
      updated: Date.now()
    };
    let newMutations = Object.assign({}, this.state.mutations);

    Object.keys(this.props.errors.merge).forEach(key => {
      delete newMutations.edited[key];
      if (
        typeof newMutations.added === 'undefined' ||
        (
          typeof newMutations.added !== 'undefined' &&
          typeof newMutations.added[key] === 'undefined'
        )
      ) {
        delete newMutations.index[key];
        delete newMutations.data[key];
      }
    });

    newState.mutations = newMutations;
    newState.items = this.filterListItems(newMutations);

    this.setState((state, props) => {
      return newState;
    }, () => this.props.onDismissError('merge'));

  };

  onConfirmMergeModal = () => {
    this.action_save(true)
  };

  onConfirmDuplicateModal = () => {

    let newState = {
      updated: Date.now()
    };
    let newMutations = Object.assign({}, this.state.mutations);

    Object.keys(this.props.errors.duplicate).forEach(key => {
      delete newMutations.added[key];
      delete newMutations.index[key];
      delete newMutations.data[key];
      if (typeof newMutations.edited !== 'undefined') {
        delete newMutations.edited[key];
      }
    });

    newState.mutations = newMutations;
    newState.items = this.filterListItems(newMutations);

    this.setState((state, props) => {
      return newState;
    }, this.action_save);

  };


  openLanguagesModal = () => {

    let columns = [];

    // setup array
    this.getLanguages()
        .forEach(language => columns.push({
          ...this.props.columns[language.key],
          label: language.alias,
          key: language.key
        }));

    columns = columns.sort((a, b) => a.order - b.order);

    this.setState({
      modalLanguages: {
        open: true,
        columns: columns
      }
    });
  };
  closeLanguagesModal = () => {
    this.setState({
      modalLanguages: {
        open: false,
        columns: []
      }
    });
  };
  confirmLanguagesModal = () => {

    const _state = this.state.modalLanguages;
    let settings = {};

    _state.columns.forEach((language, index) => {
      settings[language.key] = {
        visible: language.visible,
        order: index
      };
    })

    this.props.onToggleLanguageColumn(settings);
    this.closeLanguagesModal();
  };

  onDragLanguageEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const currentColumns = (
        this.state.modalLanguages &&
        this.state.modalLanguages.columns
    ) ? [...this.state.modalLanguages.columns] : [];

    const reorderedColumns = reorderArray(
        currentColumns,
        result.source.index,
        result.destination.index
    );

    this.setState({
      modalLanguages: {
        open: true,
        columns: reorderedColumns
      }
    });

  }

  getLanguageListStyle = (
      isDraggingOver
  ) => ({
    width: '100%',
    background: isDraggingOver ? "#CFD8DC" : "#ECEFF1",
  });

  getLanguageItemStyle = (
      isDragging,
      draggableStyle
  ) => {
    const extend = (isDragging) ? {
      flexDirection: 'row',
      display: 'flex',
      flexWrap: 'nowrap',
      justifyContent: 'flex-start',
      alignContent: 'center',
      alignItems: 'center',
      boxSizing: 'border-box',
      boxShadow: '0 8px 16px -8px rgba(0,0,0,0.4), 0 0 24px 0 rgba(0,0,0,0.2)'
    } : {};

    return {
      userSelect: "none",
      background: '#ECEFF1',
      ...extend,
      ...draggableStyle,
    }
  };

  onAddRow = (newRow) => {

    const props = this.props;
    const time = Date.now();

    let goToLastPage = false;

    let newMutations = Object.assign({}, this.state.mutations);
    newMutations.added[newRow.id] = time;
    newMutations.data[newRow.id] = newRow;
    newMutations.index[newRow.id] = time;

    let newState = {
      mutations: newMutations
    };

    if (props.isLastPage) {
      newState.items = this.filterListItems(newMutations);
    } else {
      goToLastPage = true;
    }

    this.setState(newState, () => {
      if (goToLastPage) {
        props.goToPage(props.totalPageCount, true)
      } else {
        this.tableBodyRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'end'
        });
      }
    });

  };

  render() {

    const state = this.state;
    const props = this.props;

    const isLoading = props.isLoading;
    const isSaving = props.isSaving;
    const languages = this.getVisibleLanguages();

    const mutationsCount = Object.keys(state.mutations.index).length;
    const renderKey = (state.updated || 0) + mutationsCount;

    return (
      <React.Fragment>
        <TaToolbar>
          <TaButton
            icon={'settings'}
            tooltip={'Settings'}
            onClick={props.handleOpenSettingModal}
            disabled={isLoading}
          />
          <TaButton
            icon={'archive'}
            tooltip={'Import'}
            onClick={props.handleOpenImportModal}
            disabled={isLoading}
          />
          <TaButton
            icon={'unarchive'}
            tooltip={'Export'}
            onClick={props.handleExport}
            disabled={isLoading}
          />
          <TaButton
            icon={'remove_circle'}
            tooltip={'Remove'}
            onClick={(event) => this.openRemoveWarningModal()}
            disabled={state.selected.isNone}
          />
          <TaButton
              icon={'visibility'}
              // label={'Languages'}
              tooltip={'Visible Languages'}
              onClick={(event) => this.openLanguagesModal()}
              // disabled={state.selected.isNone}
          />
          <TaToolbarGroup>
            <TaInputGroup>
              <TaInputText
                name="search"
                onEvent={(e) => props.onSearchInput(e.data.value)}
                iconBefore="search"
                placeholder="Search Term"
                clear={true}
              />
            </TaInputGroup>
          </TaToolbarGroup>
          <TaButton
            type="submit"
            icon={'check'}
            label={'Save'}
            tooltip={(mutationsCount) ? ('Save ' + mutationsCount + ' change' + ((mutationsCount > 1) ? 's' : '')) : ''}
            disabled={mutationsCount === 0}
            onClick={(e) => this.action_save()}
            isLoading={isSaving}
          />
        </TaToolbar>
        <TaPane
          flex={'auto'}
          style={{position: 'relative'}}
        >
          <GlossaryEditor
              renderKey={renderKey}
              languages={languages}
              rows={props.items}

              selected={state.selected}
              mutations={state.mutations}

              isLoading={isLoading}

              currentPage={props.page}
              rowsPerPage={props.itemsPerPage}

              onAddRow={this.onAddRow}
              onRowSelect={this.selected_toggleItem}
              onRowUnselect={this.selected_unselectAll}
              onCellEdit={this.onCellEdit}

              tableBodyRef={this.tableBodyRef}
          >
            <GlossaryEditorFooter
              currentPage={props.page}
              itemsPerPage={props.itemsPerPage}
              totalItemCount={props.totalItemCount}
              totalPageCount={props.totalPageCount}
              isFiltered={props.isFiltered}
              goToPage={props.goToPage}
            />
          </GlossaryEditor>

          {/*<TaCard style={{position: 'absolute', left: '8px', right: '8px', top: '8px', bottom: '8px'}}>*/}
          {/*  <TaTable scroll={'y'} flex={'auto'}>*/}
          {/*    <TaTableHeader look={'card'} className={'ta-table--vertical-lines'}>*/}
          {/*      <TaTableRow>*/}
          {/*        <TaTableCell width={outerCellWidth} color={'light'}>#</TaTableCell>*/}
          {/*        <TaTableCellGroup>*/}
          {/*        {*/}
          {/*          (!languages.length) ? (*/}
          {/*            <TaTableCell/>*/}
          {/*          ) : (*/}
          {/*            languages.map((language) => (*/}
          {/*                <TaTableCell*/}
          {/*                    key={language.key}*/}
          {/*                    width={languageCellWidth + '%'}*/}
          {/*                >*/}
          {/*                  {language.alias}*/}
          {/*                </TaTableCell>*/}
          {/*              )*/}
          {/*            )*/}
          {/*          )*/}
          {/*        }*/}
          {/*        </TaTableCellGroup>*/}
          {/*        <TaTableCell width={outerCellWidth}/>*/}
          {/*      </TaTableRow>*/}
          {/*      <GlossaryAddRow*/}
          {/*          languages={languages}*/}
          {/*          cellWidth={outerCellWidth}*/}
          {/*          onCommit={this.onAddRow}*/}
          {/*      />*/}
          {/*    </TaTableHeader>*/}
          {/*    <TaTableBody key={renderKey} className={'ta-table--vertical-lines'}>*/}
          {/*      {*/}
          {/*        (isLoading) ? (*/}
          {/*          <TaTableRow>*/}
          {/*            <TaTableCell>*/}
          {/*              Loading...*/}
          {/*            </TaTableCell>*/}
          {/*          </TaTableRow>*/}
          {/*        ) : (*/}

          {/*          state.items.map((row, rowIndex) => {*/}
          {/*            const isChecked = (*/}
          {/*              state.selected &&*/}
          {/*              state.selected.items &&*/}
          {/*              state.selected.items[row.id]*/}
          {/*            );*/}

          {/*            const isMutated = mutations.index[row.id] || 0;*/}
          {/*            const reactKey = (isMutated) ? (row.id + '_' + isMutated) : row.id;*/}
          {/*            const term = (isMutated) ? Object.assign({}, row, mutations.data[row.id]) : row;*/}
          {/*            const rowNo = (thisPage > 1) ? props.itemsPerPage * thisPage - props.itemsPerPage + rowIndex + 1 : rowIndex + 1;*/}

          {/*            return (*/}
          {/*              <TaTableRow key={reactKey}*/}
          {/*                          isSelected={isChecked}*/}
          {/*              >*/}
          {/*                <TaTableCell*/}
          {/*                  width={outerCellWidth}*/}
          {/*                  color={(term.is_pending) && 'alert'}*/}
          {/*                  onClick={(event) => this.selected_toggleItem(row.id)}*/}
          {/*                >*/}
          {/*                  <TaText*/}
          {/*                    color={(term.is_pending) ? 'alert' : 'light'}*/}
          {/*                  >{rowNo}{(term.is_pending) && <span>&nbsp;&#9679;</span>}</TaText>*/}
          {/*                </TaTableCell>*/}
          {/*                {*/}
          {/*                  languages.map((language) => {*/}
          {/*                    const cellContent = term[language.key] || '';*/}
          {/*                    // const error = this.cellIsInValid(cellContent);*/}
          {/*                    return (*/}
          {/*                      <TaTableCellEditable*/}
          {/*                        key={language.key}*/}
          {/*                        // error={error}*/}
          {/*                        onFocus={this.selected_unselectAll}*/}
          {/*                        onBlur={(value) => this.onCellEdit(*/}
          {/*                          language.key,*/}
          {/*                          term[language.key],*/}
          {/*                          value,*/}
          {/*                          row,*/}
          {/*                          rowIndex*/}
          {/*                        )}*/}
          {/*                      >*/}
          {/*                        {term[language.key]}*/}
          {/*                      </TaTableCellEditable>*/}
          {/*                    );*/}
          {/*                  })*/}
          {/*                }*/}
          {/*                <TaTableCell width={outerCellWidth}/>*/}
          {/*              </TaTableRow>*/}
          {/*            );*/}

          {/*          })*/}
          {/*        )*/}

          {/*      }*/}
          {/*      <div ref={this.tableBodyRef}/>*/}
          {/*    </TaTableBody>*/}
          {/*    <TaTableFooter look={'card'}>*/}
          {/*      <TaTableRow>*/}
          {/*        <TaTableCell*/}
          {/*          valign={'center'}*/}
          {/*        >{labelRows}*/}
          {/*        </TaTableCell>*/}
          {/*        <TaTableCell*/}
          {/*          align={'right'}*/}
          {/*          valign={'center'}*/}
          {/*        >*/}
          {/*          <div>{labelPages}</div>*/}
          {/*        </TaTableCell>*/}
          {/*        <TaTableCell width={40} noWrapper>*/}
          {/*          <div style={{padding: '8px 8px 8px 0'}}>*/}
          {/*            <TaButton*/}
          {/*              icon={'arrow_back'}*/}
          {/*              disabled={(thisPage <= 1)}*/}
          {/*              onClick={(e) => props.goToPage(thisPage - 1)}*/}
          {/*            />*/}
          {/*          </div>*/}
          {/*        </TaTableCell>*/}
          {/*        <TaTableCell width={40} noWrapper>*/}
          {/*          <div style={{padding: '8px 8px 8px 0'}}>*/}
          {/*            <TaButton*/}
          {/*              icon={'arrow_forward'}*/}
          {/*              disabled={(thisPage === props.totalPageCount)}*/}
          {/*              onClick={(e) => props.goToPage(thisPage + 1)}*/}
          {/*            />*/}
          {/*          </div>*/}
          {/*        </TaTableCell>*/}
          {/*      </TaTableRow>*/}
          {/*    </TaTableFooter>*/}
          {/*  </TaTable>*/}
          {/*</TaCard>*/}
        </TaPane>

        <TaModal
          isOpen={state.modalWarning.open}
          onCancel={this.closeRemoveWarningModal}
        >
          <TaModalHeader
            label={state.modalWarning.title}
            onCancel={this.closeRemoveWarningModal}
            warning={!state.modalWarning.isError}
            error={state.modalWarning.isError}
          />
          <TaModalContent scroll={'auto'}>
            <TaModalContentMargin>
              {state.modalWarning.body}
            </TaModalContentMargin>
          </TaModalContent>
          <TaModalFooter>
            <TaButton
              type="reset"
              icon={'clear'}
              label={'Cancel'}
              onClick={this.closeRemoveWarningModal}
            />
            <TaButton
              type="submit"
              icon={(state.modalWarning.isError) ? '' : 'check'}
              label={(state.modalWarning.isError) ? 'Review' : 'Confirm'}
              onClick={this.onRemoveWarningModalConfirm}
              isLoading={state.modalWarning.saving}
            />
          </TaModalFooter>
        </TaModal>
        <TaModal
          isOpen={!!(props.errors && props.errors.hasDuplicate)}
          onCancel={(e) => props.onDismissError('duplicate')}
        >
          {
            (props.errors && props.errors.hasDuplicate) && (
              <React.Fragment>
                <TaModalHeader
                  icon={'warning'}
                  warning
                  label={'Duplicate Term'}
                  onCancel={(e) => props.onDismissError('duplicate')}
                />
                <TaModalContent type={'text'}>
                  <p>The Changes have <b>not been saved</b>, because following <i>added</i> term pairs already exist:
                  </p>
                  <ul>
                    {Object.keys(props.errors.duplicate).map(key => {
                      return <li
                        key={key}>{languages.map(language => props.errors.items[key][language.key]).join(' / ')}</li>;
                    })}
                  </ul>
                  <p>Would you like to <b>remove the duplicates</b> and try to save again?</p>
                </TaModalContent>
                <TaModalFooter>
                  <TaButton
                    type="reset"
                    icon={'clear'}
                    label={'Cancel'}
                    onClick={(e) => props.onDismissError('duplicate')}
                  />
                  <TaButton
                    type="submit"
                    icon={'check'}
                    iconAlign={'right'}
                    label={'Yes'}
                    onClick={this.onConfirmDuplicateModal}
                    isLoading={state.isSaving}
                  />
                </TaModalFooter>
              </React.Fragment>
            )}
        </TaModal>
        <TaModal
          isOpen={!!(props.errors && props.errors.hasMerge)}
          onCancel={this.onDismissMergeModal}
        >
          {
            (props.errors && props.errors.hasMerge) && (
              <React.Fragment>
                <TaModalHeader
                  icon={'warning'}
                  warning
                  label={'Duplicate Term'}
                  onCancel={(e) => props.onDismissError('merge')}
                />
                <TaModalContent type={'text'}>
                  <p>The following <i>updated</i> term pairs already exist:</p>
                  <ul>
                    {Object.keys(props.errors.merge).map(key => {
                      return <li
                        key={key}>{languages.map(language => props.errors.items[key][language.key]).join(' / ')}</li>;
                    })}
                  </ul>
                  <p>Would you like to <b>merge the duplicates</b>?</p>
                </TaModalContent>
                <TaModalFooter>
                  <TaButton
                    type="reset"
                    icon={'clear'}
                    label={'No, discard'}
                    onClick={this.onDismissMergeModal}
                  />
                  <TaButton
                    type="submit"
                    icon={'check'}
                    iconAlign={'right'}
                    label={'Yes, merge'}
                    onClick={this.onConfirmMergeModal}
                    isLoading={state.isSaving}
                  />
                </TaModalFooter>
              </React.Fragment>
            )}
        </TaModal>
        <TaModal
            isOpen={state.modalLanguages.open}
            onCancel={this.closeLanguagesModal}
        >
          <TaModalHeader
              label={'Configure Language Columns'}
              onCancel={this.closeLanguagesModal}
          />
          <TaModalContent scroll={'auto'}>
            {/*<TaModalContentMargin>*/}
            <table className={'ta-table-html'} style={{userSelect:'none'}}>
              <thead>
              <tr>
                <td style={{paddingLeft: '16px', width: '50%'}}>Language</td>
                <td style={{textAlign: 'center', width: '25%'}}>Visible</td>
                <td style={{textAlign: 'center', width: '25%'}}>Position</td>
              </tr>
              </thead>
              {
                (
                    !!state.modalLanguages &&
                    !!state.modalLanguages.columns
                ) ? (
                    <DragDropContext onDragEnd={this.onDragLanguageEnd}>
                      <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <tbody
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={this.getLanguageListStyle(snapshot.isDraggingOver)}
                            >
                            {state.modalLanguages.columns.map((language, index) => {
                              const languageKey = language.key;
                              return (
                                  <Draggable
                                      key={languageKey}
                                      draggableId={languageKey}
                                      index={index}
                                  >
                                    {(provided, snapshot) => (
                                        <tr
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={this.getLanguageItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}
                                        >
                                          <td style={{
                                            paddingLeft: '16px',
                                            display: (snapshot.isDragging) ? 'flex' : 'table-cell',
                                            // display: (snapshot.isDragging) ? 'flex' : 'table-cell',
                                            width: '50%',
                                            flex: (snapshot.isDragging) ? '1 0 50%' : 'auto',
                                          }}>
                                            <b>{language.label}</b>
                                          </td>
                                          <td style={{
                                            textAlign: 'center',
                                            display: (snapshot.isDragging) ? 'flex' : 'table-cell',
                                            width: '25%',
                                            flex: (snapshot.isDragging) ? '0 0 25%' : 'auto',
                                            justifyContent: 'center',
                                            alignContent: 'center',
                                            alignItems: 'center',
                                          }}>
                                            <div style={{display: 'inline-block'}}>
                                              <TaInputCheckbox
                                                  key={languageKey}
                                                  name={language.label}
                                                  value={language.visible}
                                                  onEvent={(e) => {
                                                    if (e.name === 'change') {
                                                      this.setState({
                                                        modalLanguages: {
                                                          open: true,
                                                          columns: [
                                                            ...state.modalLanguages.columns
                                                          ].map((_l) => (_l.key === languageKey) ? {
                                                            ..._l,
                                                            visible: e.data.value
                                                          } : _l)
                                                        }
                                                      })
                                                    }
                                                  }}
                                              />
                                            </div>
                                          </td>
                                          <td style={{
                                            textAlign: 'center',
                                            display: (snapshot.isDragging) ? 'flex' : 'table-cell',
                                            flex: (snapshot.isDragging) ? '0 0 25%' : 'auto',
                                            justifyContent: 'center',
                                            alignContent: 'center',
                                            alignItems: 'center',
                                            width: '25%'
                                          }}>#{index + 1}</td>
                                        </tr>
                                    )}
                                  </Draggable>
                              )
                            })}
                            {provided.placeholder}
                            </tbody>
                        )}
                      </Droppable>
                    </DragDropContext>
                ) : null
              }
              <tfoot>
              <tr><td colSpan={3} style={{paddingLeft:'16px'}}>Drag row to change position</td></tr>
              </tfoot>
            </table>
            {/*</TaModalContentMargin>*/}
          </TaModalContent>
          <TaModalFooter>
            <TaButton
                type="reset"
                icon={'clear'}
                label={'Cancel'}
                onClick={this.closeLanguagesModal}
            />
            <TaButton
                type="submit"
                icon={'check'}
                label={'Done'}
                onClick={this.confirmLanguagesModal}
            />
          </TaModalFooter>
        </TaModal>
      </React.Fragment>
    );
  }
}

export default GlossaryDetail;