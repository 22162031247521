import React, {Fragment} from "react";
import TaTableCell from "components/taUi/taTable/taTableCell";
import {TaText} from "components/taUi/taText/taText";
import {TaTableRow} from "components/taUi/taTable/taTableRow";
import {TaButton} from "components/taUi/taButton/taButton";
import {TaTableFooter} from "components/taUi/taTable/taTableFooter";

export default function GlossaryEditorFooter(
    {
        currentPage,
        itemsPerPage,
        totalItemCount,
        totalPageCount,
        isFiltered,
        goToPage
    }
) {

    const hasPages = totalItemCount > itemsPerPage;
    const labelPages = (hasPages) ? 'Page ' + currentPage + ' of ' + totalPageCount : '';

    const fromRow = (currentPage * itemsPerPage) - itemsPerPage + 1;
    const toRow = fromRow - 1 + itemsPerPage;

    let labelRowsFragments = [];
    if (isFiltered) {
        labelRowsFragments
            .push(<Fragment key={'a'}><b>Found {totalItemCount} rows</b> </Fragment>)
        if (hasPages) {
            labelRowsFragments
                .push(<TaText key={'b'} color={'light'}>| </TaText>)
        }
    }
    if (hasPages) {
        labelRowsFragments
            .push(<Fragment
                key={'c'}>Row {fromRow} - {(currentPage < totalPageCount) ? toRow : totalItemCount} of {totalItemCount}</Fragment>)
    } else if (!isFiltered) {
        labelRowsFragments
            .push(<Fragment key={'d'}>Found {totalItemCount} rows</Fragment>)
    }
    return (
        <TaTableFooter look={'card'}>
            <TaTableRow>
                <TaTableCell
                    valign={'center'}
                >{labelRowsFragments}</TaTableCell>
                <TaTableCell
                    align={'right'}
                    valign={'center'}
                >
                    <div>{labelPages}</div>
                </TaTableCell>
                <TaTableCell width={40} noWrapper>
                    <div style={{padding: '8px 8px 8px 0'}}>
                        <TaButton
                            icon={'arrow_back'}
                            disabled={(currentPage <= 1)}
                            onClick={() => goToPage(currentPage - 1)}
                        />
                    </div>
                </TaTableCell>
                <TaTableCell width={40} noWrapper>
                    <div style={{padding: '8px 8px 8px 0'}}>
                        <TaButton
                            icon={'arrow_forward'}
                            disabled={(currentPage === totalPageCount)}
                            onClick={() => goToPage(currentPage + 1)}
                        />
                    </div>
                </TaTableCell>
            </TaTableRow>
        </TaTableFooter>
    )
}
