import plainTextToHtml from "components/taUi/util/plainTextToHtml";

const canHaveTextNodeTags = [
  "html",
  "body",
  "title",
  "section",
  "nav",
  "article",
  "aside",
  "header",
  "footer",
  "address",
  "main",
  "p",
  "hr",
  "pre",
  "blockquote",
  "ol",
  "ul",
  "li",
  "dl",
  "dt",
  "dd",
  "figcaption",
  "div",
  "a",
  "em",
  "strong",
  "small",
  "s",
  "cite",
  "q",
  "dfn",
  "abbr",
  "ruby",
  "rt",
  "rp",
  "data",
  "time",
  "code",
  "var",
  "samp",
  "kbd",
  "sub",
  "sup",
  "i",
  "b",
  "u",
  "mark",
  "bdi",
  "bdo",
  "span",
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6",
  "label",
  "button",
  "textarea",
  "option",
  "output",
  "legend",
  "caption",
  "table",
  "thead",
  "tbody",
  "tfoot",
  "tr",
  "td",
  "th",
  "summary",
]

const html5Colors = {
  aliceblue: "#f0f8ff",
  antiquewhite: "#faebd7",
  aqua: "#00ffff",
  aquamarine: "#7fffd4",
  azure: "#f0ffff",
  beige: "#f5f5dc",
  bisque: "#ffe4c4",
  black: "#000000",
  blanchedalmond: "#ffebcd",
  blue: "#0000ff",
  blueviolet: "#8a2be2",
  brown: "#a52a2a",
  burlywood: "#deb887",
  cadetblue: "#5f9ea0",
  chartreuse: "#7fff00",
  chocolate: "#d2691e",
  coral: "#ff7f50",
  cornflowerblue: "#6495ed",
  cornsilk: "#fff8dc",
  crimson: "#dc143c",
  cyan: "#00ffff",
  darkblue: "#00008b",
  darkcyan: "#008b8b",
  darkgoldenrod: "#b8860b",
  darkgray: "#a9a9a9",
  darkgreen: "#006400",
  darkkhaki: "#bdb76b",
  darkmagenta: "#8b008b",
  darkolivegreen: "#556b2f",
  darkorange: "#ff8c00",
  darkorchid: "#9932cc",
  darkred: "#8b0000",
  darksalmon: "#e9967a",
  darkseagreen: "#8fbc8f",
  darkslateblue: "#483d8b",
  darkslategray: "#2f4f4f",
  darkturquoise: "#00ced1",
  darkviolet: "#9400d3",
  deeppink: "#ff1493",
  deepskyblue: "#00bfff",
  dimgray: "#696969",
  dodgerblue: "#1e90ff",
  firebrick: "#b22222",
  floralwhite: "#fffaf0",
  forestgreen: "#228b22",
  fuchsia: "#ff00ff",
  gainsboro: "#dcdcdc",
  ghostwhite: "#f8f8ff",
  gold: "#ffd700",
  goldenrod: "#daa520",
  gray: "#800000",
  green: "#008000",
  greenyellow: "#adff2f",
  honeydew: "#f0fff0",
  hotpink: "#ff69b4",
  indianred: "#cd5c5c",
  indigo: "#4b0082",
  ivory: "#fffff0",
  khaki: "#f0e68c",
  lavender: "#e6e6fa",
  lavenderblush: "#fff0f5",
  lawngreen: "#7cfc00",
  lemonchiffon: "#fffacd",
  lightblue: "#add8e6",
  lightcoral: "#f08080",
  lightcyan: "#e0ffff",
  lightgoldenrodyellow: "#fafad2",
  lightgray: "#d3d3d3",
  lightgreen: "#90ee90",
  lightpink: "#ffb6c1",
  lightsalmon: "#ffa07a",
  lightseagreen: "#20b2aa",
  lightskyblue: "#87cefa",
  lightslategray: "#778899",
  lightsteelblue: "#b0c4de",
  lightyellow: "#ffffe0",
  lime: "#00ff00",
  limegreen: "#32cd32",
  linen: "#faf0e6",
  magenta: "#ff00ff",
  maroon: "#800000",
  mediumaquamarine: "#66cdaa",
  mediumblue: "#0000cd",
  mediumorchid: "#ba55d3",
  mediumpurple: "#9370db",
  mediumseagreen: "#3cb371",
  mediumslateblue: "#7b68ee",
  mediumspringgreen: "#00fa9a",
  mediumturquoise: "#48d1cc",
  mediumvioletred: "#c71585",
  midnightblue: "#191970",
  mintcream: "#f5fffa",
  mistyrose: "#ffe4e1",
  moccasin: "#ffe4b5",
  navajowhite: "#ffdead",
  navy: "#000080",
  oldlace: "#fdf5e6",
  olive: "#808000",
  olivedrab: "#6b8e23",
  orange: "#ffa500",
  orangered: "#ff4500",
  orchid: "#da70d6",
  palegoldenrod: "#eee8aa",
  palegreen: "#98fb98",
  paleturquoise: "#afeeee",
  palevioletred: "#db7093",
  papayawhip: "#ffefd5",
  peachpuff: "#ffdab9",
  peru: "#cd853f",
  pink: "#ffc0cb",
  plum: "#dda0dd",
  powderblue: "#b0e0e6",
  purple: "#800080",
  red: "#ff0000",
  rosybrown: "#bc8f8f",
  royalblue: "#4169e1",
  saddlebrown: "#8b4513",
  salmon: "#fa8072",
  sandybrown: "#f4a460",
  seagreen: "#2e8b57",
  seashell: "#fff5ee",
  sienna: "#a0522d",
  silver: "#c0c0c0",
  skyblue: "#87ceeb",
  slateblue: "#6a5acd",
  slategray: "#708090",
  snow: "#fffafa",
  springgreen: "#00ff7f",
  steelblue: "#4682b4",
  tan: "#d2b48c",
  teal: "#008080",
  thistle: "#d8bfd8",
  tomato: "#ff6347",
  turquoise: "#40e0d0",
  violet: "#ee82ee",
  wheat: "#f5deb3",
  white: "#ffffff",
  whitesmoke: "#f5f5f5",
  yellow: "#ffff00",
  yellowgreen: "#9acd32",
};

const rgbToHex = (r, g, b) => {
  const hexR = r.toString(16).padStart(2, '0');
  const hexG = g.toString(16).padStart(2, '0');
  const hexB = b.toString(16).padStart(2, '0');
  return `#${hexR}${hexG}${hexB}`;
}

const hslToHex = (h, s, l) => {
  h /= 360;
  s /= 100;
  l /= 100;
  let r, g, b;
  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    const hue2rgb = (p, q, t) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }
  const toHex = (x) => {
    const hex = Math.round(x * 255).toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  };
  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}

const convertColorToHex = (colorValue) => {
  if (colorValue.startsWith("rgb")) {
    const rgbValues = colorValue.match(/\d+/g);
    if (rgbValues && rgbValues.length >= 3) {
      return rgbToHex(parseInt(rgbValues[0]), parseInt(rgbValues[1]), parseInt(rgbValues[2]));
    }
  } else if (colorValue.startsWith("hsl")) {
    const hslValues = colorValue.match(/[\d.]+/g);
    if (hslValues && hslValues.length >= 3) {
      return hslToHex(parseFloat(hslValues[0]), parseFloat(hslValues[1]), parseFloat(hslValues[2]));
    }
  } else {
    const hex = html5Colors[colorValue.toLowerCase()];
    if (hex) {
      return hex;
    }
  }
  return null;
}

const isColorProperty = (property) => {
  return ["color", "background-color"].includes(property);
}

const isNotHEXColorCode = (value) => {
  return !value.startsWith('#')
}

export default function handleClipboard(editor) {

  //const clipboardPlugin = editor.plugins.get('Clipboard');
  const editingView = editor.editing.view;

  editingView.document.on('clipboardInput', (event, data) => {

    if (editor.isReadOnly) {
      return;
    }

    let content;
    const [textContent, textContentLength] = handleText(data.dataTransfer);
    const [htmlContent, htmlContentLength] = handleHtml(data.dataTransfer);

    // console.log('handleClipboard - htmlContent', htmlContent);

    if (textContentLength > htmlContentLength) {
      content = textContent
    } else {
      content = htmlContent
    }
    data.content = editor.data.htmlProcessor.toView(content);
  });
}

const handleText = (dataTransfer) => {
  let content = plainTextToHtml(dataTransfer.getData('text/plain'));
  // replace all 3 types of line breaks with a space
  content = content.replace(/(\r\n|\n|\r)/gm," ");
  // replace non-break-space with a space
  content = content.replace(/(&nbsp;|&#160;)/g," ");
  // remove <p> tags
  content = content.replace(/<p[^>]*?>(.*?)<\/p>/gms, '$1 '); // '$1</br>'
  //Replace all double white spaces with single spaces
  content = content.replace(/\s+/g," ");
  //Replace white space after Japanese dot
  content = content.replace(/。 /g,"。");
  // Remove spaces from start and end of string.
  content = content.trim();
  return [content, content.length];
}

const handleHtml = (dataTransfer) => {
  const copiedContent = dataTransfer.getData('text/html');
  const container = document.createElement("div");
  container.innerHTML = copiedContent

  const setSpecificStyles = (sourceElement, targetElement) => {
    const allowedProperties = [
      "color",
      "background-color",
      "background",
      "font-weight",
      "font-style",
      "text-decoration"
    ]
    if (sourceElement.tagName == "B") targetElement.style["font-weight"] = "bold";
    if (sourceElement.tagName == "I") targetElement.style["font-style"] = "italic";
    if (sourceElement.tagName == "U") targetElement.style["text-decoration"] = "underline solid";
    const style = sourceElement.getAttribute('style'); // Get the inline style attribute
    if (style) {
      // Parse inline styles
      const styleRules = style.split(';').map(rule => rule.trim()).filter(rule => {
        // Filter out MHT UI values
        if(
            rule === 'background-color: rgba(255, 255, 255, 0.01)' ||
            rule === 'color: rgba(0, 0, 0, 0.99)'
        ) {
          return false;
        }
        return rule;
      });
      // Loop through styles and apply only allowed properties
      styleRules.forEach(rule => {
        const [property, value] = rule.split(':').map(part => part.trim());
        if (allowedProperties.includes(property)) {
          // *color value must be HEX
          if (isColorProperty(property) && isNotHEXColorCode(value)) {
            const colorCode = convertColorToHex(value);
            if (colorCode) {
              targetElement.style[property] = colorCode
            }
          }
          targetElement.style[property] = value;
        }
      });
    }
  }

  const traverseNodes = (node) => {
    if (node.nodeType === Node.TEXT_NODE && node.nodeValue) {
        const parent = node.parentNode;
        const span = document.createElement("span");
        span.textContent = node.nodeValue;
        setSpecificStyles(parent, span);
        spans.push(span);
    } else if (node.tagName && canHaveTextNodeTags.includes(node.tagName.toLowerCase())) {
        // Recursively process child nodes
        node.childNodes.forEach(traverseNodes);
    }
  }

  const spans = [];
  traverseNodes(container);
  const paragraph = document.createElement('p');
  spans.forEach(span => paragraph.appendChild(span));
  return [paragraph.innerHTML, paragraph.innerText.length]
}